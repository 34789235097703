/** @jsx jsx */
import { jsx, Grid, Box, Link } from "theme-ui";

import NextLink from "next/link";

import LogoLink from "components/logo-link";

function Footer() {
  return (
    <Grid
      justifyContent="space-between"
      columns={[null, "1fr 160px 1fr"]}
      gap={20}
      p={["l", null, "xl"]}
      sx={{ alignItems: "flex-end", textAlign: "center" }}
    >
      <NextLink href="/how-it-works" passHref>
        <Link variant="social" sx={{ mr: [null, "auto"] }}>
          How It Works
        </Link>
      </NextLink>

      <Box sx={{ display: ["none", "block"] }}>
        <LogoLink />
      </Box>

      <NextLink href="/shipping-information" passHref>
        <Link variant="social" sx={{ ml: [null, "auto"] }}>
          Shipping
        </Link>
      </NextLink>

      <Link
        variant="social"
        target="_blank"
        href="https://www.instagram.com/neuegoods/"
        sx={{ display: ["block", "none"] }}
      >
        Instagram
      </Link>

      <Link
        variant="social"
        target="_blank"
        href="https://twitter.com/neuegoods"
        sx={{ display: ["block", "none"] }}
      >
        Twitter
      </Link>
    </Grid>
  );
}

export default Footer;
