/** @jsx jsx */
import { jsx, Grid, Link } from "theme-ui";

import LogoLink from "components/logo-link";

function Header() {
  return (
    <Grid
      justifyContent="space-between"
      columns={[null, "1fr 160px 1fr"]}
      gap={20}
      px={["l", null, "xl"]}
      pt={["l", null, "xl"]}
      mb={["xxl", null, "xxxl", "xxxxl"]}
      sx={{ alignItems: "flex-end" }}
    >
      <Link
        sx={{ display: ["none", "block"], mr: "auto" }}
        href="https://www.instagram.com/neuegoods/"
        variant="social"
        target="_blank"
      >
        Instagram
      </Link>

      <LogoLink />

      <Link
        sx={{ display: ["none", "block"], ml: "auto" }}
        href="https://twitter.com/neuegoods"
        variant="social"
        target="_blank"
      >
        Twitter
      </Link>
    </Grid>
  );
}

export default Header;
