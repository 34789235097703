/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import Link from "next/link";

function LogoLink() {
  return (
    <Link href="/" passHref>
      <Box as="a" sx={{ display: "block" }}>
        <img
          src="/images/logo.svg"
          width={160}
          sx={{ display: "block", mx: "auto" }}
        />
      </Box>
    </Link>
  );
}

export default LogoLink;
