/** @jsx jsx */
import { jsx, Flex } from "theme-ui";

import Header from "components/header";
import Footer from "components/new-footer";

function Layout(props) {
  const { children, ...rest } = props;
  return (
    <>
      <Header />
      <Flex
        sx={{
          pb: ["xxxl", null, "xxxxl"],
          flex: "auto",
          flexDirection: "column",
        }}
        {...rest}
      >
        {children}
      </Flex>
      <Footer />
    </>
  );
}

export default Layout;
